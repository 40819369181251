import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./Navigation/Routes.js";
import { Toaster } from 'react-hot-toast';
import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/nice-select.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/slicknav.min.css";
import "./assets/css/owl-carousel.css";
import "./assets/css/datepicker.css";
import "./assets/css/animate.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/normalize.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/custom.css";

function App() {
  return (
    <>
      <Router>
        <AppRoutes />
        <Toaster />
      </Router>
    </>
  );
}

export default App;
