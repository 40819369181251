import React, { useEffect, useState } from "react";
import logo from "../../../src/assets/img/HD-Logo.png";
import { Link, useLocation } from "react-router-dom";
import Loader from "./loader";

export const Header = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const activePaths = ["/cardiovascular", "/anti-diabetic", "/lipid-lowering", "/pain-management"];
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setShowSpinner(false);
    }, 1000);
  }, []);

  return (
    <>
      {showSpinner && <Loader />}
      <header className="header">
        <div className="header-inner">
          <div className="container">
            <div className="inner">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-12">
                  <div className="logo main-logo-size">
                    <Link to="/">
                      <img src={logo} alt="#"></img>
                    </Link>
                  </div>
                  <div className="mobile-nav"></div>
                </div>
                <div className="col-lg-7 col-md-9 col-12">
                  <div className="main-menu">
                    <nav className="navigation">
                      <ul className="nav menu">
                        <li className={location.pathname === "/" ? "active" : ""}>
                          <Link onClick={() => {window.location.href="/"}}>Home</Link>
                        </li>
                        <li className={location.pathname === "/about-us" ? "active" : ""}>
                          <Link to={"/about-us"}>About Us</Link>
                        </li>
                        <li className={activePaths.includes(location.pathname) ? "active" : ""}>
                          <Link>
                            Products <i className="icofont icofont-rounded-down"></i>
                          </Link>
                          <ul className="dropdown">
                            <li className={location.pathname === "/cardiovascular" ? "active" : ""}>
                              <Link to={"/cardiovascular"}>Cardiovascular</Link>
                            </li>
                            <li className={location.pathname === "/anti-diabetic" ? "active" : ""}>
                              <Link to={"/anti-diabetic"}>Anti Diabetic</Link>
                            </li>
                            <li className={location.pathname === "/lipid-lowering" ? "active" : ""}>
                              <Link to={"/lipid-lowering"}>Lipid Lowering</Link>
                            </li>
                            <li className={location.pathname === "/pain-management" ? "active" : ""}>
                              <Link to={"/pain-management"}>Pain Management</Link>
                            </li>
                          </ul>
                        </li>
                        <li className={location.pathname === "/contact-us" ? "active" : ""}>
                          <Link to={"/contact-us"}>Contact Us</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-lg-2 col-12">
                  <div className="get-quote">
                    <Link to={"/contact-us"} className="btn">
                      Request a Call back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
