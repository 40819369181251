import React from "react";
import { Link, useLocation } from "react-router-dom";

export const PainManagement = () => {
  return (
    <>
      <div className="breadcrumbs overlay">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Pain Management</h2>
                <ul className="bread-list">
                  <li>
                  <Link to={"/"}>Home</Link>
                  </li>
                  <li>
                    <i className="icofont icofont-simple-right"></i>
                  </li>
                  <li className="active">Pain Management</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="pf-details section  pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-content">
                <div className="section-title">
                  <br />
                </div>
                <table className="table table-striped table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th className="font_20 semibold white col-md-3">Brand Name</th>
                      <th className="font_20 semibold white col-md-6">Content</th>
                      <th className="font_20 semibold white col-md-2">Packing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>AKILOS-100</td>
                      <td>Aceclofenac 100 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>AKILOS-CR</td>
                      <td>Aceclofenac Controlled Release 200 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>AKILOS-MR</td>
                      <td>Aceclofenac 100 mg. + Paracetamol 325 mg. + Chlorzoxazone 250 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>AKILOS-P</td>
                      <td>Aceclofenac 100 mg. + Paracetamol 325 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>AKILOS-SP</td>
                      <td>Aceclofenac 100 mg. + Paracetamol 325 mg.+ Serratiopeptidase 15 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>AKILOS-TH 4</td>
                      <td>Aceclofenac 100 mg. + Thiocolchicoside 4mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>CAFIPAR</td>
                      <td>Paracetamol 500 mg. + Caffeine 30 mg</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>DAN</td>
                      <td>Diclofenac Sodium Gastro-resistant 50 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>DAN 2X GEL</td>
                      <td>Diclofenac Diethylamine I.P. 2.32% w/w + Menthol I.P. 5% w/w + Methyl Salicylate I.P. 10 %w/w</td>
                      <td>30 GM</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>DAN GEL</td>
                      <td>Diclofenac Diethylamine 1.16% w/w + Linseed Oil 3% w/w + Methyl Salicylate 10% w/w + Menthol 5% w/w + Benzyl Alcohol 1% w/w</td>
                      <td>30 GM / 75 GM</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>DAN-MR</td>
                      <td>Diclofenac Sodium 50 mg. + Paracetamol 325 mg + Chlorzoxazone 250 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>DAN-P</td>
                      <td>Diclofenac Sodium 50 mg. + Paracetamol 325 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>DAN-SP</td>
                      <td>Diclofenac Sodium 50 mg. + Paracetamol 325 mg.+Serratiopeptidase 10 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>DAN-SR-100</td>
                      <td>Diclofenac Sodium Prolonged-Release 100 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>DCMOL</td>
                      <td>Dicyclomine Hydrochloride 20 mg. + Paracetamol 500 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>DCMOL-M</td>
                      <td>Dicyclomine Hydrochloride 10 mg. + Mefenamic Acid 250 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>NIMESON</td>
                      <td>Nimesulide 100 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>NIMESON-P</td>
                      <td>Nimesulide 100 mg. + Paracetamol 325 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>TRSON-D</td>
                      <td>Tramadol Hydrochloride 37.5 mg. + Paracetamol 325 mg. + Domperidone 10 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>TRSON-P</td>
                      <td>Tramadol Hydrochloride 37.5 mg. + Paracetamol 325 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PainManagement;
