import React from "react";
import slider3Img from "../../assets/img/slider3.jpg";
import { Link, useLocation } from "react-router-dom";

export const AboutUs = () => {
  return (
    <>
      <section className="slider">
          <div className="single-slider" style={{ backgroundImage: `url(${slider3Img})` }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="text">
                    <h1>About Us</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <section className="schedule content-height"></section>

      <section className="Feautes section pb-5">
        <div className="section-title">
          <h2>SMS | Since 1981</h2>
          <p>
            SMS Pharmaceuticals Private Limited (Formerly known as SMS Pharmaceuticals) was established in 1981 with a vision of providing quality & most affordable medicines across the nation. With 4 decades of medical service to the society, SMS is today one of the fastest growing company in Gujarat State. The base of operation being a single state, the company has very strong footing in the market. The company strictly believes in ethics and moral values throughout the operations of the
            business.
          </p>
        </div>
      </section>
      <section className="call-action overlay design" data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="content content-center">
                <p>“It has been the philosophy of the company to provide World Class Quality Medicines at Most Affordable Price even to the smallest class of the society to ensure the best healthcare possible. Consistent and sustainable growth has been a fundamental principle of the company.“</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="portfolio section pb-3">
        <div className="col-lg-12">
          <div className="section-title">
            <p>The company has more than 170+ products in the basket with its presence in different therapeutic segments like Anti-hypertensives, Anti-Diabetics, Lipid-lowering, Anti-Infective, Anti-biotics, Respiratory management, Hematinics, Anti-Allergics, Steroids, Psychotropics, Calcium and Vitamin Supplements and others. The company has its core competence in the chronic segments like Anti-Hypertensives, Anti-Diabetics and Lipid-Lowering drugs.</p>
          </div>
        </div>
      </section>

      <section className="pricing-table section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="single-table">
                <div className="table-head">
                  <div className="icon">
                    <i className="icofont icofont-trophy-alt"></i>
                  </div>
                  <h4 className="title">Our Mission</h4>
                  <div className="price">
                    <p>To be recognized as an emerging global pharmaceutical company by 2025 and make a strong presence in domestic market by offering innovative, cost effective and quality products portfolio in targeted segments.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="single-table">
                <div className="table-head">
                  <div className="icon">
                    <i className="icofont icofont-microscope-alt"></i>
                  </div>
                  <h4 className="title">Our Vision</h4>
                  <div className="price">
                    <p>To be recognized as pioneer and leader in the field of healthcare by consistently offering high quality medicines at most affordable price across India and globe for enhancing quality of life of human beings.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why-choose section">
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="choose-left">
                <h3>Who We Are</h3>
                <p>SMS was established in 2015 with a vision of providing quality & most affordable medicines across the nation. With 4 decades of medical service to the society, SMS is today one of the fastest growing company in Gujarat State.</p>
                <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="choose-right">
                <div className="video-image">
                  <div className="promo-video">
                    <div className="waves-block">
                      <div className="waves wave-1"></div>
                      <div className="waves wave-2"></div>
                      <div className="waves wave-3"></div>
                    </div>
                  </div>
                  <Link to={"https://www.youtube.com/watch?v=RFVXy6CRVR4"} className="video video-popup mfp-iframe">
                    <i className="fa fa-play"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pricing-table section small-txt">
        <div className="container content-center">
          <p>SMS is focused on increasing the momentum in the business through organic growth by launching newer and newer molecules and expanding the wings across the nation and globe.</p>
        </div>
      </section>
      <section className="call-action overlay" data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="content content-center">
                <h2>Contact Us</h2>
                <p>
                  SMS Pharmacy
                  <br />
                  UL-26 Maharana Pratap Centre,
                  <br />
                  B/H V.S. Hospital, Ellis Bridge, <br />
                  Ahmedabad, Gujarat - 380006
                </p>
                <div className="button">
                  <Link to={"/contact-us"} className="btn">
                    Request A Callback!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
