import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
const { REACT_APP_SERVICE_KEY, REACT_APP_TEMPLATE_KEY, REACT_APP_PUBLIC_KEY } = process.env;

export const FormComponent = (props) => {
  const initialState = {
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialState);

  const [nameErr, setNameErr] = useState({});
  const [emailErr, setEmailErr] = useState({});
  const [mobileNoErr, setMobileNoErr] = useState({});
  const [subjectErr, setSubjectErr] = useState({});
  const [messageErr, setMessageErr] = useState({});
  const location = useLocation();

  const formValidation = () => {
    let nameErr = {};
    let emailErr = {};
    let mobileNoErr = {};
    let subjectErr = {};
    let messageErr = {};

    let isValid = true;

    if (!formData.name) {
      nameErr.empty = "Enter Name";
      isValid = false;
    }

    if (!formData.email) {
      emailErr.empty = "Enter Email";
      isValid = false;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
      emailErr.invalidEmail = "Enter valid Email";
      isValid = false;
    }

    if (!formData.mobile) {
      mobileNoErr.emtpy = "Enter Mobile Number";
      isValid = false;
    } else if (!/^(?!0)[0-9]{10}$/.test(formData.mobile)) {
      mobileNoErr.invalidMobile = "Invalid mobile number, mobile number should be of 10 digits should not start with 0";
      isValid = false;
    }
    if (!formData.subject) {
      subjectErr.empty = "Enter Subject";
      isValid = false;
    }

    if (!formData.message) {
      messageErr.empty = "Enter Message";
      isValid = false;
    }
    setNameErr(nameErr);
    setEmailErr(emailErr);
    setMobileNoErr(mobileNoErr);
    setSubjectErr(subjectErr);
    setMessageErr(messageErr);

    return isValid;
  };

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (formValidation()) {
      const SendEmail = emailjs.sendForm(REACT_APP_SERVICE_KEY, REACT_APP_TEMPLATE_KEY, e.target, REACT_APP_PUBLIC_KEY);
      if (SendEmail) {
        toast.success("Thank you for your enquiry, our expert will reach out to you shortly!", {
          style: {
            border: '1px solid #1a76d1',
            padding: '16px',
            color: '#1a76d1',
          },
          iconTheme: {
            primary: '#1a76d1',
            secondary: '#FFFAEE',
          },
        });
      } else {
        toast("Something wrong");
      }
      setFormData(initialState);
    }
  };
  return (
    <>
      <div className="col-lg-6">
        <div className="contact-us-form">
            <h2>Contact With Us</h2>
            <p>If you have any questions please fell free to contact with us.</p>
          <form className="form" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <input type="text" name="name" maxLength={30} placeholder="Name" value={formData.name} onChange={onChange}></input>
                  {Object.keys(nameErr).map((key) => {
                    return <div className="text-danger">{nameErr[key]}</div>;
                  })}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <input type="text" name="email" maxLength={50} placeholder="Email" value={formData.email} onChange={onChange}></input>
                  {Object.keys(emailErr).map((key) => {
                    return <div className="text-danger">{emailErr[key]}</div>;
                  })}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <input type="text" name="mobile" maxLength={10} placeholder="Mobile No." value={formData.mobile} onChange={onChange}></input>
                  {Object.keys(mobileNoErr).map((key) => {
                    return <div className="text-danger">{mobileNoErr[key]}</div>;
                  })}
                </div>
              </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input type="text" name="subject" maxLength={50} placeholder="Subject" value={formData.subject} onChange={onChange}></input>
                    {Object.keys(subjectErr).map((key) => {
                      return <div className="text-danger">{subjectErr[key]}</div>;
                    })}
                  </div>
                </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <textarea name="message" maxLength={50} placeholder="Write Your Message Here....." value={formData.message} onChange={onChange}></textarea>
                  {Object.keys(messageErr).map((key) => {
                    return <div className="text-danger">{messageErr[key]}</div>;
                  })}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group login-btn">
                  <button className="btn" type="submit">
                    Send
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormComponent;
