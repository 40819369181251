import React from "react";
import { Link, useLocation } from "react-router-dom";

export const LipidLowering = () => {
  return (
    <>
      <div className="breadcrumbs overlay">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Lipid Lowering</h2>
                <ul className="bread-list">
                  <li>
                  <Link to={"/"}>Home</Link>
                  </li>
                  <li>
                    <i className="icofont icofont-simple-right"></i>
                  </li>
                  <li className="active">Lipid Lowering</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="pf-details section  pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-content">
                <div className="section-title">
                  <br />
                </div>
                <table className="table table-striped table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th className="font_20 semibold white col-md-3">Brand Name</th>
                      <th className="font_20 semibold white col-md-6">Content</th>
                      <th className="font_20 semibold white col-md-2">Packing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>ROSUSON-10</td>
                      <td>Rosuvastatin 10 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>ROSUSON-20</td>
                      <td>Rosuvastatin 20 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>ROSUSON-40</td>
                      <td>Rosuvastatin 40 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>ROSUSON-5</td>
                      <td>Rosuvastatin 5 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>ROSUSON-ASP 75</td>
                      <td>Rosuvastatin10 mg. + Aspirin 75 mg.(As gastro resistant tablets)</td>
                      <td>10 CAP</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>ROSUSON-CV 10</td>
                      <td>Rosuvastatin IP 10mg + Clopidogrel 75 mg</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>ROSUSON-F 10</td>
                      <td>Rosuvastatin 10 mg. + Fenofibrate (Micronised) 160 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>ROSUSON-F 5</td>
                      <td>Rosuvastatin 5 mg. + Fenofibrate (Micronised) 160 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>ROSUSON-GOLD 10</td>
                      <td>Rosuvastatin 10 mg ( As pellets ) + Aspirin 75 mg.(As enteric coated pellets ) + Clopidogrel 75 mg.</td>
                      <td>10 CAP</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>ROSUSON-GOLD 20</td>
                      <td>Rosuvastatin 20 mg ( As pellets ) + Aspirin 75 mg.(As enteric coated pellets ) + Clopidogrel 75 mg.</td>
                      <td>10 CAP</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>TORVASON-10</td>
                      <td>Atorvastatin 10 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>TORVASON-20</td>
                      <td>Atorvastatin 20 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>TORVASON-40</td>
                      <td>Atorvastatin 40 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>TORVASON-80</td>
                      <td>Atorvastatin 80 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>TORVASON-EZ</td>
                      <td>Atorvastatin 10 mg. + Ezetimibe 10 mg</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>TORVASON-F</td>
                      <td>Atorvastatin 10 mg. + Fenofibrate (Micronised) 160 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LipidLowering;
