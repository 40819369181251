import React, { useState, useEffect } from "react";
import FormComponent from "../common/FormComponent";
import { Link, useLocation } from "react-router-dom";

export const ContactUs = () => {
  return (
    <>
      <div className="breadcrumbs overlay">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Contact Us</h2>
                <ul className="bread-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="icofont icofont-simple-right"></i>
                  </li>
                  <li className="active">Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="contact-us section">
        <div className="container">
          <div className="inner">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-us-left">
                  <div id="myMap">
                    <iframe width="100%" height="100%" loading="lazy" allowFullScreen src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.068804619777!2d72.5698056!3d23.0190191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e845509af6851%3A0x8fd65d738f6dc138!2sSarvajanik%20Medical%20Stores!5e0!3m2!1sen!2sin!4v1620691585630!5m2!1sen!2sin"></iframe>
                  </div>
                </div>
              </div>
              <FormComponent/>
            </div>
          </div>
          <div className="contact-info">
            <div className="row">
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont icofont-ui-call"></i>
                  <div className="content p-text">
                    <p>7405516114, 7405455363 sms@mailinator.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont icofont-google-map"></i>
                  <div className="content p-text">
                    <p>UL-26 Maharana Pratap Centre,B/H V.S. Hospital, Ellis Bridge, Ahmedabad, Gujarat - 380006</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont icofont-wall-clock"></i>
                  <div className="content p-text">
                    <p>
                      Monday - Saturday
                      <br /> 9 AM - 9 PM <br />
                      Sunday 10 AM - 2 PM{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
