import React from "react";
import { Link, useLocation } from "react-router-dom";

export const AntiDiabetic = () => {
  return (
    <>
      <div className="breadcrumbs overlay">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Anti Diabetic</h2>
                <ul className="bread-list">
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li>
                    <i className="icofont icofont-simple-right"></i>
                  </li>
                  <li className="active">Anti Diabetic</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="pf-details section  pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-content">
                <div className="section-title">
                  <br />
                </div>
                <table className="table table-striped table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th className="font_20 semibold white col-md-3">Brand Name</th>
                      <th className="font_20 semibold white col-md-6">Content</th>
                      <th className="font_20 semibold white col-md-2">Packing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>DGSON-10</td>
                      <td>Dapaglif Propanediol Monohydrate eq. to Dapagliflozin 10 mg</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>DGSON-5</td>
                      <td>Dapaglif Propanediol Monohydrate eq. to Dapagliflozin 5 mg</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>DGSON-M 00</td>
                      <td>Dapaglif Propanediol Monohydrate eq. to Dapagliflozin 10 mg + Metformin Hydrochloride IP 1000 mg (As Extended Release form)</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>DGSON-M 0</td>
                      <td>Dapaglif Propanediol Monohydrate eq. to Dapagliflozin 10 mg + Metformin Hydrochloride IP 500 mg (As Extended Release form)</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>DGSON-V 1</td>
                      <td>Dapaglif Propanediol Monohydrate eq. to Dapagliflozin 10 mg + Vildagliptin IP 100 mg (as sustained release)</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>DGSON-VM</td>
                      <td>Dapaglif Propanediol Monohydrate eq. to Dapagliflozin 10 mg + Vildagliptin IP 100 mg (as sustained release) + Metformin Hydrochloride IP 1000 mg (as sustained release)</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>DGSON-VM</td>
                      <td>Dapaglif Propanediol Monohydrate eq. to Dapagliflozin 10 mg + Vildagliptin IP 100 mg (as sustained release) + Metformin Hydrochloride IP 500 mg (as sustained release)</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>FORSON</td>
                      <td>Metformirochloride 500 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>FORSON-S0</td>
                      <td>Metformirochloride Prolonged-Release 1000 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>FORSON-S</td>
                      <td>Metformirochloride Prolonged-Release 500 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>GLIMISON</td>
                      <td>Glimeprimg +Voglibose 0.2 mg. + Metformin Hydrochloride. SR 500 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>GLIMISON</td>
                      <td>Glimeprimg +Voglibose 0.2 mg. + Metformin Hydrochloride. SR 500 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>GLIMISON.5</td>
                      <td>Glimepir.5 mg. + Metformin Hydrochloride Prolonged-Release 500 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>GLIMISON1</td>
                      <td>Glimepir mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>GLIMISON1</td>
                      <td>Glimepir mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>GLIMISONh1</td>
                      <td>Glimepir mg. + Metformin Hydrochloride PR 500 mg.</td>
                      <td>15 TAB</td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>GLIMISONORTE</td>
                      <td>Glimepir mg. + Metformin Hydrochloride PR 1000 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>GLIMISONh1</td>
                      <td>Glimepir mg. + Metformin Hydrochloride PR 500 mg.</td>
                      <td>15 TAB</td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>GLIMISONORTE</td>
                      <td>Glimepir mg. + Metformin Hydrochloride PR 1000 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>GLIMISON</td>
                      <td>Glimepir mg. + Metformin Hydrochloride SR 500 mg. + Pioglitazone Hydrochloride 15 mg.</td>
                      <td>15 TAB</td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>GLIMISON</td>
                      <td>Glimepir mg. + Metformin Hydrochloride SR 500 mg. + Pioglitazone Hydrochloride 15 mg.</td>
                      <td>15 TAB</td>
                    </tr>
                    <tr>
                      <td>22</td>
                      <td>GLYSON</td>
                      <td>Glipizidg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>23</td>
                      <td>GLYSON-M</td>
                      <td>Glipizidg. + Metformin Hydrochloride 500 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>24</td>
                      <td>LGLIP-5</td>
                      <td>Linaglip mg</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>LGLIP-M h1</td>
                      <td>Linaglip.5 mg + Metformin Hydrochloride ER 500mg</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>26</td>
                      <td>SITASON-h1</td>
                      <td>Sitagliphosphate Monohydrate 100mg</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>27</td>
                      <td>SITASON-1</td>
                      <td>Sitagliphosphate Monohydrate 50mg</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>28</td>
                      <td>SITASON-/10</td>
                      <td>Sitagliphosphate Monohydrate IP Equ. To Sitagliptin 100mg + Dapagliflozin Propanediol Monohydrate eq. to Dapagliflozin 10 mg</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>29</td>
                      <td>SITASON-00</td>
                      <td>Sitagliphosphate Monohydrate IP Equ. To Sitagliptin 100mg + Dapagliflozin Propanediol Monohydrate eq. to Dapagliflozin 10 mg + Metformin Hydrochloride IP 1000 mg (As Extended Release )</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>30</td>
                      <td>SITASON-0</td>
                      <td>Sitagliphosphate Monohydrate IP Equ. To Sitagliptin 100mg + Dapagliflozin Propanediol Monohydrate eq. to Dapagliflozin 10 mg + Metformin Hydrochloride IP 1000 mg (As Extended Release )</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>31</td>
                      <td>SITASON-500</td>
                      <td>Sitagliphosphate Monohydrate IP Equ. To Sitagliptin 50mg + Metformin Hydrochloride IP 500 mg (As Extended Release )</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>TENLISONh1</td>
                      <td>Teneligl 20 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>33</td>
                      <td>TENLISON00</td>
                      <td>Teneligl 20 mg. + Metformin Hydrochloride ER 1000 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>34</td>
                      <td>TENLISON0</td>
                      <td>Teneligl 20 mg. + Metformin Hydrochloride ER 500 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>35</td>
                      <td>VILASON-1</td>
                      <td>Vildagli50 mg </td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>36</td>
                      <td>VILASON-</td>
                      <td>Vildagli50 mg + Metformin Hydrochloride 500 mg. </td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>37</td>
                      <td>VILASON-0</td>
                      <td>VildagliSR 100 mg</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>38</td>
                      <td>VOGLYSON</td>
                      <td>Voglibos mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>39</td>
                      <td>VOGLYSON</td>
                      <td>Voglibos mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>VOGLYSON</td>
                      <td>Voglibos mg. + Glimepiride 1 mg. + Metformin Hydrochloride SR 500 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>41</td>
                      <td>VOGLYSON</td>
                      <td>Voglibos mg. + Glimepiride 2 mg. + Metformin Hydrochloride SR. 500 mg.</td>
                      <td>10 TAB</td>
                    </tr>
                    <tr>
                      <td>42</td>
                      <td>VOGLYSON2</td>
                      <td>Voglibos mg. + Metformin Hydrochloride SR 500 mg.</td>
                      <td>15 TAB</td>
                    </tr>
                    <tr>
                      <td>43</td>
                      <td>VOGLYSON3</td>
                      <td>Voglibos mg. + Metformin Hydrochloride SR 500 mg.</td>
                      <td>15 TAB</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AntiDiabetic;
