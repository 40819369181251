import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../Layout";
import Dashboard from "../components/views/Dashboard.js";
import ContactUs from "../components/views/ContactUs.js";
import AboutUs from "../components/views/AboutUs.js";
import Cardiovascular from "../components/views/Product/Cardiovascular.js";
import AntiDiabetic from "../components/views/Product/AntiDiabetic.js";
import LipidLowering from "../components/views/Product/LipidLowering.js";
import PainManagement from "../components/views/Product/PainManagement.js";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/cardiovascular" element={<Cardiovascular />} />
        <Route path="/anti-diabetic" element={<AntiDiabetic />} />
        <Route path="/lipid-lowering" element={<LipidLowering />} />
        <Route path="/pain-management" element={<PainManagement />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
