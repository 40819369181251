import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sectionImg from "../../assets/img/section-img.png";
import contactImg from "../../assets/img/contact-img.png";
import client5Img from "../../assets/img/client5.png";
import client4Img from "../../assets/img/client4.png";
import client3Img from "../../assets/img/client3.png";
import client2Img from "../../assets/img/client2.png";
import client1Img from "../../assets/img/client1.png";
import blog3Img from "../../assets/img/blog3.jpg";
import blog2Img from "../../assets/img/blog2.jpg";
import blog1Img from "../../assets/img/blog1.jpg";
import pf4Img from "../../assets/img/pf4.jpg";
import pf3Img from "../../assets/img/pf3.jpg";
import pf2Img from "../../assets/img/pf2.jpg";
import pf1Img from "../../assets/img/pf1.jpg";
import pf1slider2Img from "../../assets/img/slider2.jpg";
import sliderImg from "../../assets/img/slider.jpg";
import slider3Img from "../../assets/img/slider3.jpg";
import FormComponent from "../common/FormComponent.js";

export const Home = () => {
  const [showCount, setShowCount] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      $(".counter").append("+");
    }, 3000);
  }, [showCount]);

  $(document).ready(function() {
    $('.get-pro-title').click(function(e) {
      e.preventDefault();
      $('.pro-features').toggleClass('active');
    });
  });

  return (
    <>
      <ul className="pro-features">
        <Link className="get-pro-title" to={"#"}>
          Let's Connect!
        </Link>
        <li className="big-title">Feel free to reach us at,</li>
        <li>7405516114</li>
        <li>7405455363</li>
        <li>
          <Link to={"mailto:sms@mailinator.com"}>sms@mailinator.com</Link>
        </li>
        <div className="button">
          <Link to={"/contact-us"} className="btn">
            Get a Call Back
          </Link>
        </div>
      </ul>
      <section className="slider">
        <div className="hero-slider">
          <div className="single-slider" style={{ backgroundImage: `url(${pf1slider2Img})` }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="text">
                    <h1>
                      We Provide <span>Medical</span> Services That You Can <span>Trust!</span>
                    </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed nisl pellentesque, faucibus libero eu, gravida quam. </p>
                    <div className="button">
                      <Link to={"#"} className="btn">
                        Get Appointment
                      </Link>
                      <Link to={"#"} className="btn primary">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="single-slider" style={{ backgroundImage: `url(${sliderImg})` }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="text">
                    <h1>
                      We Provide <span>Medical</span> Services That You Can <span>Trust!</span>
                    </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed nisl pellentesque, faucibus libero eu, gravida quam. </p>
                    <div className="button">
                      <Link to={"#"} className="btn">
                        Get Appointment
                      </Link>
                      <Link to={"/about-us"} className="btn primary">
                        About Us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="single-slider" style={{ backgroundImage: `url(${slider3Img})` }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="text">
                    <h1>
                      We Provide <span>Medical</span> Services That You Can <span>Trust!</span>
                    </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed nisl pellentesque, faucibus libero eu, gravida quam. </p>
                    <div className="button">
                      <Link to={"#"} className="btn">
                        Get Appointment
                      </Link>
                      <Link to={"/contact-us"} className="btn primary">
                        Contact Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="schedule">
        <div className="container">
          <div className="schedule-inner">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 ">
                <div className="single-schedule first">
                  <div className="inner">
                    <div className="icon">
                      <i className="fa fa-ambulance"></i>
                    </div>
                    <div className="single-content">
                      <span>Lorem Amet</span>
                      <h4>Emergency Cases</h4>
                      <p>Lorem ipsum sit amet consectetur adipiscing elit. Vivamus et erat in lacus convallis sodales.</p>
                      <Link to={"#"}>
                        LEARN MORE<i className="fa fa-long-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12">
                <div className="single-schedule last">
                  <div className="inner">
                    <div className="icon">
                      <i className="icofont icofont-ui-clock"></i>
                    </div>
                    <div className="single-content">
                      <span>Schedule</span>
                      <h4>Opening Hours</h4>
                      <ul className="time-sidual">
                        <li className="day">
                          Monday - Saturday <span>9 AM - 9 PM</span>
                        </li>
                        <li className="day">
                          Sunday<span>10 AM - 2 PM</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="single-schedule middle">
                  <div className="inner">
                    <div className="icon">
                      <i className="icofont icofont-prescription"></i>
                    </div>
                    <div className="single-content">
                      <span>Fusce Porttitor</span>
                      <h4>Doctors Timetable</h4>
                      <p>Lorem ipsum sit amet consectetur adipiscing elit. Vivamus et erat in lacus convallis sodales.</p>
                      <Link to={"#"}>
                        LEARN MORE<i className="fa fa-long-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Feautes section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>We Are Always Ready to Help You & Your Family</h2>
                <img src={sectionImg} alt="#"></img>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit praesent aliquet. pretiumts</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="single-features">
                <div className="signle-icon">
                  <i className="icofont icofont-ambulance-cross"></i>
                </div>
                <h3>Emergency Help</h3>
                <p>Lorem ipsum sit, consectetur adipiscing elit. Maecenas mi quam vulputate.</p>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="single-features">
                <div className="signle-icon">
                  <i className="icofont icofont-medical-sign-alt"></i>
                </div>
                <h3>Enriched Pharmecy</h3>
                <p>Lorem ipsum sit, consectetur adipiscing elit. Maecenas mi quam vulputate.</p>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="single-features last">
                <div className="signle-icon">
                  <i className="icofont icofont-stethoscope"></i>
                </div>
                <h3>Medical Treatment</h3>
                <p>Lorem ipsum sit, consectetur adipiscing elit. Maecenas mi quam vulputate.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="fun-facts" className="fun-facts section overlay">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-fun">
                <i className="icofont icofont-home"></i>
                <div className="content">
                  <span className="counter">3000</span>
                  <p>Monthly Satisfied Customers</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-fun">
                <i className="icofont icofont-user-alt-3"></i>
                <div className="content">
                  <span className="counter">7000</span>
                  <p>Pharma Products Available</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-fun">
                <i className="icofont icofont-simple-smile"></i>
                <div className="content">
                  <span className="counter">200</span>
                  <p>Pharma Company Connected</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-fun">
                <i className="icofont icofont-table"></i>
                <div className="content">
                  <span className="counter">3</span>
                  <p>Government Certificate</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="why-choose section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>We Offer Different Services To Improve Your Health</h2>
                <img src={sectionImg} alt="#"></img>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit praesent aliquet. pretiumts</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="choose-left">
                <h3>Who We Are</h3>
                <p>SMS was established in 2015 with a vision of providing quality & most affordable medicines across the nation. With 4 decades of medical service to the society, SMS is today one of the fastest growing company in Gujarat State.</p>
                <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="choose-right">
                <div className="video-image">
                  <div className="promo-video">
                    <div className="waves-block">
                      <div className="waves wave-1"></div>
                      <div className="waves wave-2"></div>
                      <div className="waves wave-3"></div>
                    </div>
                  </div>
                  <Link to={"https://www.youtube.com/watch?v=RFVXy6CRVR4"} className="video video-popup mfp-iframe">
                    <i className="fa fa-play"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="call-action overlay" data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="content">
                <h2>Do you need Emergency Medical Care? Call @ 7405516114, 7405455363</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque porttitor dictum turpis nec gravida.</p>
                <div className="button">
                  <Link to={"/contact-us"} className="btn">
                    Contact Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="portfolio section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>We Maintain Cleanliness Rules Inside Our Hospital</h2>
                <img src={sectionImg} alt="#"></img>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit praesent aliquet. pretiumts</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="owl-carousel portfolio-slider">
                <div className="single-pf">
                  <img src={pf1Img} alt="#"></img>
                  <Link to={"portfolio-details.html"} className="btn">
                    View Details
                  </Link>
                </div>
                <div className="single-pf">
                  <img src={pf2Img} alt="#"></img>
                  <Link to={"portfolio-details.html"} className="btn">
                    View Details
                  </Link>
                </div>
                <div className="single-pf">
                  <img src={pf3Img} alt="#"></img>
                  <Link to={"portfolio-details.html"} className="btn">
                    View Details
                  </Link>
                </div>
                <div className="single-pf">
                  <img src={pf4Img} alt="#"></img>
                  <Link to={"portfolio-details.html"} className="btn">
                    View Details
                  </Link>
                </div>
                <div className="single-pf">
                  <img src={pf1Img} alt="#"></img>
                  <Link to={"portfolio-details.html"} className="btn">
                    View Details
                  </Link>
                </div>
                <div className="single-pf">
                  <img src={pf2Img} alt="#"></img>
                  <Link to={"portfolio-details.html"} className="btn">
                    View Details
                  </Link>
                </div>
                <div className="single-pf">
                  <img src={pf3Img} alt="#"></img>
                  <Link to={"portfolio-details.html"} className="btn">
                    View Details
                  </Link>
                </div>
                <div className="single-pf">
                  <img src={pf4Img} alt="#"></img>
                  <Link to={"portfolio-details.html"} className="btn">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>We Offer Different Services To Improve Your Health</h2>
                <img src={sectionImg} alt="#"></img>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit praesent aliquet. pretiumts</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-prescription"></i>
                <h4>
                  <Link to={"service-details.html"}>General Treatment</Link>
                </h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus dictum eros ut imperdiet. </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-tooth"></i>
                <h4>
                  <Link to={"service-details.html"}>Teeth Whitening</Link>
                </h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus dictum eros ut imperdiet. </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-heart-alt"></i>
                <h4>
                  <Link to={"service-details.html"}>Heart Surgery</Link>
                </h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus dictum eros ut imperdiet. </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-listening"></i>
                <h4>
                  <Link to={"service-details.html"}>Ear Treatment</Link>
                </h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus dictum eros ut imperdiet. </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-eye-alt"></i>
                <h4>
                  <Link to={"service-details.html"}>Vision Problems</Link>
                </h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus dictum eros ut imperdiet. </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-blood"></i>
                <h4>
                  <Link to={"service-details.html"}>Blood Transfusion</Link>
                </h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus dictum eros ut imperdiet. </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="clients overlay">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="owl-carousel clients-slider">
                <div className="single-clients">
                  <img src={client1Img} alt="#"></img>
                </div>
                <div className="single-clients">
                  <img src={client2Img} alt="#"></img>
                </div>
                <div className="single-clients">
                  <img src={client3Img} alt="#"></img>
                </div>
                <div className="single-clients">
                  <img src={client4Img} alt="#"></img>
                </div>
                <div className="single-clients">
                  <img src={client5Img} alt="#"></img>
                </div>
                <div className="single-clients">
                  <img src={client1Img} alt="#"></img>
                </div>
                <div className="single-clients">
                  <img src={client2Img} alt="#"></img>
                </div>
                <div className="single-clients">
                  <img src={client3Img} alt="#"></img>
                </div>
                <div className="single-clients">
                  <img src={client4Img} alt="#"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="appointment">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>We Are Always Ready to Help You. Request A CallBack</h2>
                <img src={sectionImg} alt="#"></img>
              </div>
            </div>
          </div>
          <div className="row">
            <FormComponent />
            <div className="col-lg-6 col-md-12 ">
              <div className="appointment-image">
                <img src={contactImg} alt="#"></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
